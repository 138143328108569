import {AuthPageState} from "~/utils/enums/AuthPageState";
import {companyStore} from "~/store/companyStore";
import {storeToRefs} from "pinia";

export function goToResetPasswordState(currentState:AuthPageState) {
    currentState = AuthPageState.ResetPassword;
}

export function getHiddenEmail  (email:string) {
    let finalHiddenEmail = '';
    try{
        const brokenEmailParts = email.split('@');
        const emailUserName = brokenEmailParts[0];
        const emailUserNameLength = emailUserName.length;
        finalHiddenEmail = emailUserName[0]+emailUserName[1]+'***'+emailUserName[emailUserNameLength-1]+'@'+brokenEmailParts[1];
    }catch (e){
        console.log(e)
        finalHiddenEmail = email;
    }
    return finalHiddenEmail;
}